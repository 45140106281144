import { Box, Stack } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { TOKEN_PARAMS } from 'common/consts';
import { setLocalStorageEmailExchange } from 'common/localStorage';
import { EntryScreen, EntryScreenCard } from 'components/EntryScreen';
import { LoginForm } from 'components/Form';
import { ButtonOutlined } from 'components/Inputs/Button';
import { Link } from 'components/Inputs/Link';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNonAuthTranslations } from 'translations/hooks';
import { getProcessEnv } from 'utils';

const Login: NextPage = () => {
  const emailField = useRef<string>('');
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { authState, oktaAuth } = useOktaAuth();

  const [redirectRoute, setRedirectRoute] =
    useState<string>('/?initialLoad=true');
  const [error, setError] = useState<string>('');
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [redirectLoading, setRedirectLoading] = useState<boolean>(false);

  useEffect(() => {
    if (router.query.path) {
      const path = String(router.query.path);
      if (path.startsWith('http')) {
        setRedirectRoute('/?redirectPath=' + path.split('?')[0]);
      } else {
        setRedirectRoute(path);
      }
      router.replace('/login', undefined, { shallow: true });
    }
  }, [router]);

  const oktaLogin = () => {
    setRedirectLoading(true);
    oktaAuth.signInWithRedirect({ originalUri: redirectRoute });
  };
  const formLoginHandler = (email: string, password: string) => {
    setError('');
    setFormLoading(true);
    emailField.current = email;
    const userCredentials = { username: email, password };

    oktaAuth
      .signInWithCredentials(userCredentials)
      .then(({ sessionToken, status }) => {
        if (status === 'PASSWORD_EXPIRED') {
          setLocalStorageEmailExchange(emailField.current);
          router.push('/change-temp-password');

          return;
        } else if (sessionToken) {
          oktaAuth.signInWithRedirect({
            originalUri: redirectRoute,
            sessionToken,
          });
        } else {
          setError(
            //todo: errors
            formatMessage({
              id: 'error.badSessionToken',
              defaultMessage: 'Bad sessionToken',
            })
          );
          setFormLoading(false);
        }
      })
      .catch((err) => {
        setError(
          err?.errorSummary ??
            // todo: errors
            formatMessage({
              id: 'error.authFailed',
              defaultMessage: 'Authentication failed',
            })
        );
        setFormLoading(false);
      });
  };

  const googleLogin = () => {
    oktaAuth.token.getWithRedirect({
      ...TOKEN_PARAMS,
      redirectUri: redirectRoute,
    });
  };

  if (authState?.isAuthenticated) {
    router.replace(redirectRoute);
  }

  const forgotPasswordHandler = () => {
    router.push('/forgot-password');
  };

  // translations
  const {
    loginPageTitle,
    logInLabel,
    logInWithOktaLabel,
    // logInWithGoogleLabel,
    forgotPassLabel,
    orLabel,
    accountLoggingInSupportLabel,
  } = useNonAuthTranslations();

  if (authState && !authState.isAuthenticated) {
    return (
      <EntryScreen
        headersTitle={loginPageTitle}
        supportLabel={accountLoggingInSupportLabel}
      >
        <EntryScreenCard title={logInLabel} error={error}>
          <LoginForm
            onSubmitCallback={formLoginHandler}
            loading={formLoading}
            disabled={redirectLoading}
          />
          <Box marginTop="8px" marginBottom="34px">
            <Link
              onClick={forgotPasswordHandler}
              disabled={formLoading || redirectLoading}
            >
              {forgotPassLabel}
            </Link>
          </Box>
          <Box>
            {!isOktaBtnHidden && (
              <Stack spacing={2}>
                <Box display="flex" justifyContent="center">
                  {orLabel}
                </Box>
                <ButtonOutlined
                  onClick={oktaLogin}
                  size="large"
                  loading={redirectLoading}
                  disabled={formLoading}
                >
                  {logInWithOktaLabel}
                </ButtonOutlined>
                {/* <ButtonOutlined
                  onClick={googleLogin}
                  size="large"
                  loading={redirectLoading}
                  disabled={formLoading}
                >
                  {logInWithGoogleLabel}
                </ButtonOutlined> */}
              </Stack>
            )}
          </Box>
        </EntryScreenCard>
      </EntryScreen>
    );
  }

  return null;
};

const processEnv = getProcessEnv();

const isOktaBtnHidden = processEnv.NEXT_PUBLIC_OKTA_SSO_LOGIN_BTN === 'hidden';

export default Login;
